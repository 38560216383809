import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpService } from './shared/services/http-service';
import { TokenService } from './shared/services/token.service';
import { ApiConfig } from './shared/config/api.config';
import { WebappConfig } from './shared/config/webApp.config';

const STORAGE_ITEM_KEY = 'token';
const HEADER_NAME = 'Authorization';
const AUTH_SCHEME = 'Bearer ';
const WHITELISTED_DOMAINS = [
  'localhost:3030',
  'qaportal.c9pg.com:3030',
  'portal.c9pg.com:3010',
  'testportal.c9pg.com:3010'
]

export function initConfig(config: ApiConfig) {
  return () => config.getConfig();
}

export function initWebappConfifg(config: WebappConfig) {
  return () => config.getConfig();
}

export function jwtTokenGetter(){
	if(navigator.cookieEnabled && typeof window.localStorage !== 'undefined'){
  		return localStorage.getItem(STORAGE_ITEM_KEY) || sessionStorage.getItem(STORAGE_ITEM_KEY);
	}
}

@NgModule({
  declarations: [
	AppComponent
	// PaymentComponent
  ],
  imports: [
	BrowserModule,
	BrowserAnimationsModule,
	HttpClientModule,
	JwtModule.forRoot({
	  config:{
		tokenGetter: jwtTokenGetter,
		whitelistedDomains: WHITELISTED_DOMAINS,
		headerName: HEADER_NAME,
		authScheme: AUTH_SCHEME,
		throwNoTokenError: false,
	  }
	}),
	ToastrModule.forRoot({
	  maxOpened: 2,
	  autoDismiss: true,
	  preventDuplicates: true
	}),
	AppRoutingModule,
	FormsModule,
	ReactiveFormsModule,
	TooltipModule.forRoot()
  ],
  providers: [
	ApiConfig,
	{
	  provide: APP_INITIALIZER,
	  useFactory: initConfig,
	  deps: [ApiConfig],
	  multi: true
	},
	WebappConfig,
	{
	  provide: APP_INITIALIZER,
	  useFactory: initWebappConfifg,
	  deps: [WebappConfig],
	  multi: true
	},
	HttpService,
	TokenService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

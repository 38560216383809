import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiConfig } from "../config/api.config";
@Injectable()
export class HttpService {
  API_HOST: string = '';
  service: any = {};
  authHttp: any = {};
  authenticatedAPI: boolean = false; //checks for authenticated http request (authHttp) or non-authenticated http request (http)
  constructor(
    protected http: HttpClient,
    private apiConfig: ApiConfig
    // protected notificationService: NotificationService
  ) {
    // this.authHttp = new AuthHttp(new AuthConfig(), http);
    this.API_HOST = apiConfig.API_HOST;
    console.log('httpService: API_HOST',this.API_HOST);
  }

	buildUrl(parameters): string {
		let qs = '';
		for (const key of Object.keys(parameters)) {
			const value = parameters[key];
			// if (value) {
			qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
			// }
		}
		if (qs.length > 0) {
			qs = qs.substring(0, qs.length - 1); //chop off last "&"
		}
		return qs;
	}

	get(url: string, elementID: any, headers: any = null): Observable<any> {
		console.log('Inside http service', url, elementID, headers);
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		// TODO: Patch Job needs review
		this.service = this.http;
		return this.service
			.get(`${this.API_HOST}/api/${url}/` + elementID)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}

	getAll(url: string, params: any, noGrid: boolean = false): Observable<any> {
		let query = '';
		const limit = params.limit ? params.limit : 10;
		const page = params.page ? params.page : 1;
		const keyword = params.keyword ? params.keyword : 'null';
		const skip = limit * (page - 1);
		const gridQuery = '$limit=' + limit + '&$skip=' + skip + '&keyword=' + keyword;

		delete params.limit;
		delete params.skip;
		delete params.keyword;
		delete params.page;

		if (noGrid || params.noGrid) {
			delete params.noGrid;
			query = this.buildUrl(params);
		} else {
			delete params.noGrid;
			query = gridQuery + '&' + this.buildUrl(params);
		}

		console.log('query--->', query);
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.get(`${this.API_HOST}/api/${url}/?` + query)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}

	pay(url: string, element: any):Observable<any>{
		this.service = this.http;
		return this.service
			.post(url, element)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}

	store(url: string, element: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.post(`${this.API_HOST}/api/${url}/`, element)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}

	update(url: string, elementID: string, newElement: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.put(`${this.API_HOST}/api/${url}/` + elementID, newElement)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}

	destroy(url: string, elementID: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		// return Observable.throw('error');
		return this.service
			.delete(`${this.API_HOST}/api/${url}/` + elementID)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}

	patch(url: string, elementID: string, updatedElement: any): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		return this.service
			.patch(`${this.API_HOST}/api/${url}/` + elementID, updatedElement)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			);
	}

	getConstants(endPoint: string, tag: string): Observable<any> {
		// this.service = this.authenticatedAPI ? this.authHttp : this.http;
		this.service = this.http;
		console.log('this.service', this.service);
		const query = `tag=${tag}`;
		return this.service
			.get(`${this.API_HOST}/api/${endPoint}/?${query}`)
			.pipe(
				map((response: Response) => response),
				catchError((err)=> {
					console.log('get-err:',err);
					throw err.error;
				})
			)
	}
}

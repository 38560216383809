import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
	private _token: string = '';
	private _checkOutToken: string = '';

	constructor() {}

	get token(): string {
		return this._token;
	}

	set token(token: string) {
		// console.log('token: token service--->', token);
		this._token = token;
	}
	
	get checkOutToken(): string {
		return this._checkOutToken;
	}

	set checkOutToken(token: string) {
		// console.log('token: token service--->', token);
		this._checkOutToken = token;
	}
}

import { Component, OnInit, ViewContainerRef } from "@angular/core";

// import { Location } from "@angular/common";
// import { Response, Http, Headers } from "@angular/http";
// import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
// import { Router, ActivatedRoute, Params } from "@angular/router";
// // import {TooltipModule } from 'ngx-bootstrap';
// import { WEB_APP_HOST } from './shared/config/webApp.config'
// import { HttpService } from "./shared/services/http-service";
// import { VTerminal } from "./models/vTerminal.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  private viewContainerRef: ViewContainerRef;
  
  constructor(
    viewContainerRef: ViewContainerRef,
  ) {
      this.viewContainerRef = viewContainerRef;
    }

  ngOnInit() {
  }
}
